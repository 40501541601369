import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LoadingService } from './services/loading.service';
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language.service';
import { ThemeService } from './services/theme.service';
import { Title } from '@angular/platform-browser';
import { FaviconService } from './services/favicon.service';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    SpinnerComponent
  ],
  template: `
    <router-outlet />
    <app-spinner *ngIf="loading$ | async"  />
  `,
})
export class AppComponent implements AfterViewChecked {
  private loadingService = inject(LoadingService);
  private cdRef=inject(ChangeDetectorRef);
  private translate = inject(TranslateService);
  private languageService = inject(LanguageService);
  private themeService = inject(ThemeService);
  private titleService = inject(Title);
  private faviconService = inject(FaviconService);

  loading$ = this.loadingService.loading$;
  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
    const lang = this.languageService.getLanguage();
    if (!lang()) {
      this.translate.setDefaultLang('pt-br');
      const selectedLanguage = this.translate.getBrowserLang();
      if (selectedLanguage === 'pt') {
        this.translate.use('pt-br');
        this.languageService.setLanguage('pt-br');
      } else {
        const language = selectedLanguage?.match(/pt-br|en|es/) ? selectedLanguage : 'en';
        this.translate.use(language);
        this.languageService.setLanguage(language);
      }
    }
    const systemType = sessionStorage.getItem('systemType') as string
    if (systemType === 'CARGILL') {
      this.titleService.setTitle('Cargill');
      this.faviconService.changeFavicon('assets/images/cargill/favicon.ico');
      this.themeService.setVariable('--primary', '#008542');
      this.themeService.setVariable('--primary-dark', '#006622');
      this.themeService.setVariable('--primary-light', '#40ca84');
      this.themeService.setVariable('--primary-200', '#b5e7a0');
    }
    if (systemType === 'ACELEN') {
      this.titleService.setTitle('Acelen');
      this.faviconService.changeFavicon('assets/images/acelen/favicon.png');
      this.themeService.setVariable('--primary', '#0074fc');
      this.themeService.setVariable('--primary-dark', '#0f52a0');
      this.themeService.setVariable('--primary-light', '#1d85ff');
      this.themeService.setVariable('--primary-200', '#80b5f2');
    }
    if (systemType === 'IRANCHO') {
      this.titleService.setTitle('iRancho');
      this.faviconService.changeFavicon('assets/images/irancho/favicon.png');
      this.themeService.setVariable('--primary', '#b1c800');
      this.themeService.setVariable('--primary-dark', '#8ca615');
      this.themeService.setVariable('--primary-light', '#ccf21b');
      this.themeService.setVariable('--primary-200', '#c6e340');
    }
    if (systemType === 'MINERVA') {
      this.titleService.setTitle('Minerva');
      this.faviconService.changeFavicon('assets/images/minerva/favicon.png');
    }
    if (systemType === 'QIMA') {
      this.titleService.setTitle('Qima');
      this.faviconService.changeFavicon('assets/images/qima/favicon.ico');
    }
  }
}
