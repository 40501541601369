import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { authGuard } from './guards/auth.guard';
import { clientPanelGuard } from './guards/client-panel.guard';
import { MinervaLoginComponent } from './pages/minerva-login/minerva-login.component';
import { CargillLoginComponent } from './pages/cargill-login/cargill-login.component';
import { AcelenLoginComponent } from './pages/acelen-login/acelen-login.component';
import { IranchoLoginComponent } from './pages/irancho-login/irancho-login.component';

export const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'minerva', component: MinervaLoginComponent },
  { path: 'minerva/login', component: MinervaLoginComponent },
  { path: 'cargill', component: CargillLoginComponent },
  { path: 'cargill/login', component: CargillLoginComponent },
  { path: 'acelen', component: AcelenLoginComponent },
  { path: 'acelen/login', component: AcelenLoginComponent },
  { path: 'irancho', component: IranchoLoginComponent },
  { path: 'irancho/login', component: IranchoLoginComponent },
  { path: 'login/client', component: LoginComponent },
  { path: 'panel', loadChildren: () => import('../app/pages/panel/panel.routes').then(e => e.PANEL_ROUTES), canActivate: [authGuard] },
  { path: 'client-panel', loadChildren: () => import('../app/pages/client-panel/client-panel.routes').then(e => e.CLIENT_PANEL_ROUTES), canActivate: [clientPanelGuard] },
  { path: 'public-panel', loadChildren: () => import('../app/pages/public-panel/public-panel.routes').then(e => e.PUBLIC_PANEL_ROUTES) },
  { path: '**', redirectTo: 'panel' },
];
