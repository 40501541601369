import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {

  private defaultFavicon = 'assets/favicon.ico';  // Caminho do favicon padrão
  private faviconLink: HTMLLinkElement | null = null;

  constructor() {
    this.faviconLink = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  }

  changeFavicon(faviconUrl: string) {
    if (this.faviconLink) {
      this.faviconLink.href = faviconUrl;
    } else {
      this.createFaviconLink(faviconUrl);
    }
  }

  private createFaviconLink(faviconUrl: string) {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = faviconUrl;
    document.head.appendChild(link);
    this.faviconLink = link;
  }

  resetFavicon() {
    this.changeFavicon(this.defaultFavicon);
  }
}
