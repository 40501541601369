import { CanActivateFn, Router } from '@angular/router';

export const clientPanelGuard: CanActivateFn = (route, state) => {
  const router = new Router;
  const token = sessionStorage.getItem('token');
  const userItem = sessionStorage.getItem('user');
  const user = userItem ? JSON.parse(userItem) : null;

  if (user.authorities.some((item: string) => item === 'ROLE_QIMA_REQUESTER' || item === 'ROLE_MINERVA_REQUESTER' || item === 'ROLE_ACELEN_REQUESTER' || item === 'ROLE_IRANCHO_REQUESTER') && !user.confirmPersonalData) {
    return true;
  }
  router.navigate(['/']);
  return false;
};
