import { AfterViewInit, Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CertRequestsService } from '../../services/cert-requests.service';
import { ClientProviderService } from '../../services/client-provider.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { GenericInputComponent } from '../../components/generic-input/generic-input.component';
import { ThemeService } from '../../services/theme.service';
import { FaviconService } from '../../services/favicon.service';

@Component({
  selector: 'app-cargill-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    GenericInputComponent,
    TranslateModule,
  ],
  templateUrl: './cargill-login.component.html',
  styleUrl: './cargill-login.component.scss'
})
export class CargillLoginComponent implements AfterViewInit {
private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private _snackBar = inject(MatSnackBar);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private certRequestsService = inject(CertRequestsService);
  private clientProviderService = inject(ClientProviderService);
  private translate = inject(TranslateService);
  private titleService = inject(Title);
  private themeService = inject(ThemeService);
  private faviconService = inject(FaviconService);

  public showLogin = true;
  public showChangePassword = false;
  public showPassword = false;
  public showForgotPassword = false;
  public showConfirmingEmail = false;

  form: FormGroup = this.fb.group({
    username: ['', Validators.required],
    currentPassword: ['', Validators.required],
  });

  registerForm: FormGroup = this.fb.group({
    name: ['', Validators.required],
    document: ['', Validators.required],
    registerNumber: ['', Validators.required],
    telephone: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  changePasswordForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    oldPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength(8), this.passwordStrengthValidator]],
    confirmNewPassword: ['', Validators.required]
  });

  resetPasswordForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });

  ngOnInit(): void {
    this.titleService.setTitle('Cargill');
    this.faviconService.changeFavicon('assets/images/cargill/favicon.ico');
    this.themeService.setVariable('--primary', '#008542');
    this.themeService.setVariable('--primary-dark', '#006622');
    this.themeService.setVariable('--primary-light', '#40ca84');
    this.themeService.setVariable('--primary-200', '#b5e7a0');
  }

  ngAfterViewInit(): void {
    this.themeService.setVariable('--primary', '#008542');
    this.themeService.setVariable('--primary-dark', '#006622');
    this.themeService.setVariable('--primary-light', '#40ca84');
    this.themeService.setVariable('--primary-200', '#b5e7a0');
  }

  handleClickRequestQuote() {
    this.showLogin = false;
  }

  handleClickCloseRegister() {
    this.showLogin = true;
    this.showForgotPassword = false;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  handleForgetPassword(): void {
    this.showForgotPassword = true;
    this.showLogin = false;
    this.showConfirmingEmail = true;
  }

  submitForm() {
    if (this.form.valid) {
      const username = this.form.get('username')?.value;
      const password = this.form.get('currentPassword')?.value;
      this.authService.login(username, password).subscribe(res => {
        const userItem = sessionStorage.getItem('user');
        const user = userItem ? JSON.parse(userItem) : {};
        if (user.authorities.some((item: string) => item === 'ROLE_MINERVA_ADMIN')) {
          this.titleService.setTitle('Cargill');
          sessionStorage.setItem('systemType', 'CARGILL');
          this.router.navigate(['/panel/dashboard']);
          this._snackBar.open(this.translate.instant('LOGIN.SUCCESS'), undefined, {
            panelClass: ['alert_success'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        } else if (user.confirmPersonalData) {
          this.router.navigate(['/public-panel/'] ,{
            queryParams: { userId: user.customerId},
          });
        } else if (user.authorities.some((item: string) => item === 'ROLE_MINERVA_REQUESTER')) {
          this.titleService.setTitle('Cargill');
          sessionStorage.setItem('systemType', 'CARGILL');
          this.router.navigate(['/client-panel/dashboard']);
        } else {
          this._snackBar.open(this.translate.instant('LOGIN.ERROR'), undefined, {
            panelClass: ['alert_success'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        }
      }, error => {
        if (error && error.status === 'CHANGE_PASSWORD') {
          this.showChangePassword = true;
          this.showLogin = false;
          this._snackBar.open(this.translate.instant('LOGIN.CHANGE_PASSWORD'), undefined, {
            panelClass: ['alert_error'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        } else {
          this._snackBar.open(this.translate.instant('LOGIN.ERROR'), undefined, {
            panelClass: ['alert_error'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        }
      })
    }
  }

  submitChangePasswordForm() {
    this.changePasswordForm.markAllAsTouched();
    const oldPassword = this.form.get('currentPassword')?.value;
    const username = this.form.get('username')?.value;
    this.changePasswordForm.get('oldPassword')?.setValue(oldPassword);
    this.changePasswordForm.get('username')?.setValue(username);

    if (this.changePasswordForm.valid) {
      const payload = {
        username: this.changePasswordForm.get('username')?.value,
        oldPassword: this.changePasswordForm.get('oldPassword')?.value,
        newPassword: this.changePasswordForm.get('newPassword')?.value,
      }
      this.authService.changePassword(payload).subscribe({
        next: res => {
          this.form.get('currentPassword')?.setValue(this.changePasswordForm.get('newPassword')?.value);
          this.submitForm();
        },
        error: error => {
          this._snackBar.open(this.translate.instant('CHANGE-PASSWORD.ERROR'), undefined, {
            panelClass: ['alert_error'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        }
      });
    }
  }

  submitResetPasswordForm(): void {
    this.resetPasswordForm.markAllAsTouched();
    if (this.resetPasswordForm.valid) {
      const email = this.resetPasswordForm.get('email')?.value;
      this.authService.sendEmailResetPassword(email).subscribe({
        next: () => {
          this._snackBar.open(this.translate.instant('FORGOT-PASSWORD.SUCCESS'), undefined, {
            panelClass: ['alert_success'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
          this.handleClickCloseRegister();
        },
        error: error => {
          this._snackBar.open(this.translate.instant('FORGOT-PASSWORD.ERROR'), undefined, {
            panelClass: ['alert_error'],
            horizontalPosition: "right",
            verticalPosition: "top"
          });
        }
      });
    }
  }

  passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value || '';

    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    const isValid =
      value.length >= 8 &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar;

    return isValid ? null : { passwordStrength: true };
  }
}
